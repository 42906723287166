<template>
  <ion-page>
    <ion-header mode="ios" class="ion-no-border">
      <ion-toolbar>
        <ion-buttons slot="start" hide-back-button="true">
          <ion-back-button text="" default-href="" @click="$emit('closeModal')"></ion-back-button>
        </ion-buttons>
      </ion-toolbar>
    </ion-header>

    <div class="header">
      <div class="title">
        <ion-text class="fw-500">{{ $t('confirm_create_duplicate_customer_title') }}</ion-text>
      </div>
    </div>

    <ion-content>
      <ion-row class="ion-justify-content-center mt-5">
        <ion-col size="12">
          <VueClientRecaptcha ref="captchaRef" :count="10" :value="inputValue" @isValid="checkValidCaptcha" />
        </ion-col>
      </ion-row>

      <ion-row class="ion-justify-content-center">
        <ion-col size="5">
          <ion-item>
            <ion-input
              v-model="inputValue"
              type="text"
              placeholder="Enter captcha"
              class="ion-text-center"
            ></ion-input>
          </ion-item>
        </ion-col>
      </ion-row>
    </ion-content>

    <ion-footer>
      <div class="footer">
        <ion-button expand="block" class="ion-text-wrap" fill="outline" @click="$emit('closeModal')">{{
          $t('cancel')
        }}</ion-button>
        <ion-button expand="block" class="ion-text-wrap" color="primary" @click="save">{{
          $t('continue')
        }}</ion-button>
      </div>
    </ion-footer>
  </ion-page>
</template>

<script>
import { toastController } from '@ionic/vue';
import { defineComponent } from 'vue';
import VueClientRecaptcha from 'vue-client-recaptcha';
import 'vue-client-recaptcha/dist/style.css';

export default defineComponent({
  name: 'ModalConfirmDuplicateCustomer',
  props: ['dataCheckDuplicateCustomer', 'postalCode'],
  inject: ['$storage'],
  components: {
    VueClientRecaptcha
  },
  data() {
    return {
      inputValue: null,
      isValidCaptcha: null
    };
  },
  methods: {
    checkValidCaptcha(valid) {
      this.isValidCaptcha = valid;
    },
    async save() {
      if (this.isValidCaptcha) {
        this.$emit('closeModal');
        this.$emit('ok');
      } else {
        this.$refs.captchaRef.resetCaptcha();
        this.inputValue = null;

        const toast = await toastController.create({
          message: this.$t('captcha_invalid'),
          position: 'top',
          color: 'danger',
          duration: 1000
        });
        toast.present();
      }
    }
  }
});
</script>

<style src="./styles/ModalSearchPostalCode.scss" lang="scss" scoped></style>
