<template>
  <ion-page>
    <ion-header mode="ios" class="ion-no-border">
      <ion-toolbar>
        <ion-buttons slot="start" hide-back-button="true">
          <ion-back-button text="" default-href="" @click="$emit('closeModal')"></ion-back-button>
        </ion-buttons>
      </ion-toolbar>
    </ion-header>

    <div class="header">
      <div class="title">
        <ion-text class="fw-500">{{ $t('companies_with_key_contact') }} '{{ phone }}'</ion-text>
        <ion-text>{{ $t('one_or_more_company1') }}.</ion-text>
      </div>
    </div>
    <div class="content-tr ion-margin-top">
      <ion-text>{{ $t('customerPage.company_name') }}</ion-text>
      <ion-text>{{ userData.user_type_id === 7 ? $t('salesperson') : '' }}</ion-text>
    </div>
    <ion-content>
      <div class="content-td">
        <div>
          <ion-list>
            <ion-radio-group mode="ios">
              <div class="list-company" v-for="(data, index) in dataCheckDuplicatePerson" :key="index">
                <div class="list-info">
                  <ion-item>
                    <ion-radio
                      :class="[
                        'ion-margin-start',
                        data.is_branch || (data.CustomerTenant?.length && !data.CustomerTenant[0].active)
                          ? 'ion-radio-disable'
                          : ''
                      ]"
                      slot="start"
                      @click="setCustomerId(data)"
                      :value="data.id"
                    ></ion-radio>
                    <div class="list-info-header">
                      <div class="list-info-title">
                        <ion-label
                          :class="[
                            data.is_branch || (data.CustomerTenant?.length && !data.CustomerTenant[0].active)
                              ? 'blur-title'
                              : ''
                          ]"
                          >{{ data.name }}</ion-label
                        >
                      </div>

                      <div
                        class="list-info-title"
                        v-if="
                          data.is_branch || (data.CustomerTenant?.length && !data.CustomerTenant[0].active)
                        "
                      >
                        <ion-label
                          @click="handleCannotConect(data)"
                          class="list-cannot-connect ion-text-right"
                        >
                          <ion-text>{{ $t('cannot_connect') }}</ion-text>
                          <img :src="Vector" />
                        </ion-label>
                      </div>

                      <div class="list-info-title" v-if="!data.is_branch">
                        <ion-row class="ion-align-items-center mr-2">
                          <icon-label
                            :class="['mr-1', data.CustomerTenant?.length ? 'text-primary' : 'text-danger']"
                          >
                            {{ data.CustomerTenant?.length ? $t('connected') : $t('not_connected') }}
                          </icon-label>

                          <ion-chip v-if="data.is_b2c" class="b2c-label-chip my-0">B2C</ion-chip>
                        </ion-row>

                        <ion-label class="ion-text-right">
                          {{ getSupplierContactPersonName(data) }}
                        </ion-label>
                      </div>

                      <div
                        :class="[
                          'list-info-address',
                          data.is_branch || (data.CustomerTenant?.length && !data.CustomerTenant[0].active)
                            ? 'blur-title'
                            : ''
                        ]"
                      >
                        <ion-text
                          >{{ data.persons[0]?.first_name !== null ? data.persons[0]?.first_name : ''
                          }}{{
                            data.persons[0]?.last_name !== null ? data.persons[0]?.last_name : ''
                          }}</ion-text
                        >
                        <ion-text>{{ `+${getPhoneNumber(data.persons[0]?.phone)}` }}</ion-text>
                      </div>
                    </div>
                  </ion-item>
                </div>
              </div>
            </ion-radio-group>
          </ion-list>
        </div>
      </div>
    </ion-content>
    <ion-footer>
      <div class="footer">
        <div class="ion-margin-bottom ion-margin-top">
          <ion-label>{{ $t('is_the_company_you_want') }}</ion-label>
        </div>
        <ion-button expand="block" class="ion-text-wrap" fill="outline" @click="$emit('closeModal')">{{
          $t('no_continue_adding_new_company')
        }}</ion-button>
        <ion-button
          expand="block"
          class="ion-text-wrap"
          color="primary"
          @click="connectSalesToCustomer"
          v-show="customerId && canConnectCustomer"
          >{{ $t('yes_connect_to_existing_company') }}</ion-button
        >
        <ion-button
          expand="block"
          class="ion-text-wrap"
          color="primary"
          @click="replaceAsContactPerson"
          v-show="customerId && !canConnectCustomer && canTakeOverCustomer"
          >{{ $t('yes_replace_as_contact_person') }}</ion-button
        >
      </div>
    </ion-footer>
  </ion-page>
</template>

<script>
import Vector from '@/assets/images/Vector.png';
import { apolloClient } from '@/main';
import {
  addPerson,
  saleCheckCanTakeOverCustomer,
  saleReplaceContactPerson
} from '@/modules/sale/services/graphql';
import { getAddressFormat, getDefaultCountryCode } from '@/modules/sale/services/libs/helper';
import { alertController, toastController } from '@ionic/vue';
import { ref } from 'vue';
export default {
  inject: ['$storage'],
  props: ['dataCheckDuplicatePerson', 'phone', 'countryCode'],
  setup() {
    const user = ref(null);

    const openToast = async function (message) {
      const toast = await toastController.create({
        message,
        position: 'top',
        color: 'primary',
        duration: 2000
      });
      return toast.present();
    };
    return {
      user,
      getAddressFormat,
      openToast,
      Vector
    };
  },
  data() {
    return {
      userData: [],
      customerId: 0,
      canTakeOverCustomer: false,
      canConnectCustomer: false
    };
  },
  async mounted() {
    this.userData = await this.$storage.getUser();
  },
  methods: {
    async handleCannotConect(payload) {
      let message = '';
      if (payload.is_branch) {
        message = this.$t('handle_not_connect_mess_branch', { customer_name: payload.name });
      }

      if (!payload.CustomerTenant?.[0]?.active) {
        message = this.$t('handle_not_connect_mess_inactive_customer', {
          customer_name: payload.name,
          tenant_name: payload.CustomerTenant?.[0].tenant_name
        });
      }

      const alert = await alertController.create({
        mode: 'ios',
        cssClass: 'custom-alertDanger',
        header: this.$t('unable_to_connect'),
        message: message,
        backdropDismiss: false,
        buttons: [
          {
            text: this.$t('OK'),
            handler: async () => {
              this.$emit('handleBackCustomerPage');
            }
          }
        ]
      });
      await alert.present();
    },
    async setCustomerId(customer) {
      this.customerId = customer.id;
      this.canConnectCustomer = !customer.userCustomers?.length;
      await this.checkCanTakeOverCustomer(customer);
    },
    async checkCanTakeOverCustomer(customer) {
      if (
        customer.userCustomers?.find((userCustomer) => userCustomer.user?.id === Number(this.userData.id))
      ) {
        this.canTakeOverCustomer = false;
        return;
      }

      try {
        const { data, errors } = await apolloClient.query({
          query: saleCheckCanTakeOverCustomer,
          variables: {
            customerId: Number(this.customerId),
            tenantId: this.userData.tenant.id
          }
        });
        if (errors) {
          this.presentAlert(errors[0].message);
          return;
        } else {
          this.canTakeOverCustomer = data.saleCheckCanTakeOverCustomer;
        }
      } catch (error) {
        this.presentAlert(error.message);
      }
    },
    getPhoneNumber(params) {
      const phone =
        params.indexOf(' ') > -1
          ? params.split(' ')[1]
          : params.length > 8
          ? params.substring(2, params.length)
          : params;
      const countryCode =
        params.indexOf(' ') > -1
          ? params.split(' ')[0]
          : params.length > 8
          ? params.substring(0, 2)
          : getDefaultCountryCode();
      const phoneNumber = countryCode + ' ' + phone;
      return phoneNumber;
    },
    async connectSalesToCustomer() {
      try {
        const { errors } = await apolloClient.mutate({
          mutation: addPerson,
          variables: {
            tenantId: (this.user && this.user.tenant && this.user.tenant.id) || 1, // Hard code for auto tagged to TreeDots tenant.
            isSelected: true,
            item: {
              customer_id: Number(this.customerId),
              phone: `${this.countryCode + this.phone}`,
              tenant_id: this.userData.tenant.id
            }
          }
        });
        if (errors) {
          this.presentAlert(errors[0].message);
          return;
        } else {
          const message = this.$t('successfully_connected_to_company');
          await this.openToast(message);
          this.$emit('handleBackCustomerPage');
        }
      } catch (error) {
        this.presentAlert(error.message);
      }
    },
    async replaceAsContactPerson() {
      try {
        const { errors } = await apolloClient.mutate({
          mutation: saleReplaceContactPerson,
          variables: {
            tenantId: this.userData.tenant.id,
            customerId: Number(this.customerId),
            userId: this.userData.id
          }
        });
        if (errors) {
          this.presentAlert(errors[0].message);
          return;
        } else {
          const message = this.$t('success_replace_contact_person');
          await this.openToast(message);
          this.$emit('handleBackCustomerPage');
        }
      } catch (error) {
        this.presentAlert(error.message);
      }
    },
    async presentAlert(params) {
      if (this.customerId === '') {
        return;
      } else {
        const alert = await alertController.create({
          mode: 'ios',
          cssClass: 'my-custom-class',
          header: this.$t('unable_to_connect'),
          message: params,
          backdropDismiss: false,
          buttons: [
            {
              text: this.$t('OK')
            }
          ]
        });
        await alert.present();
      }
    },
    getSupplierContactPersonName(customer) {
      return [customer?.userCustomers?.[0]?.user?.first_name, customer?.userCustomers?.[0]?.user?.last_name]
        .filter(Boolean)
        .join(' ');
    }
  }
};
</script>

<style src="../styles/ModalSearchPostalCode.scss" lang="scss" scoped></style>
