<template>
  <div class="step-content pb-4 overflow-hidden">
    <!-- step 1 - company information -->

    <div class="overflow-hidden">
      <div class="position-relative d-flex align-center py-3 px-5">
        <div :class="['form-icon first-step', step >= STEP.CUSTOMER ? 'bg-orange' : 'bg-grey']">
          <ion-text>1</ion-text>
        </div>
        <ion-text
          class="ml-3"
          :class="step >= STEP.CUSTOMER ? 'title-h2 fw-500' : ''"
          :color="step >= STEP.CUSTOMER ? 'primary' : 'grey'"
        >
          {{ $t('customerPage.business_information') }}
        </ion-text>
      </div>

      <transition-expand>
        <div v-if="step === STEP.CUSTOMER" class="step-item">
          <business-infomation
            @handleDataBusinessInformation="handleDataBusinessInformation"
            @handleCheckDuplicatePostalCode="handleCheckDuplicatePostalCode"
            :businessInformation="businessInformation"
            :addressRefInput="addressRefInput"
            :isBranch="isBranch"
            :defaultCreditTerm="defaultCreditTerm"
            :customerDetails="customerDetails"
            :sameAsParent_business="sameAsParent_business"
          />
        </div>
      </transition-expand>
    </div>

    <!-- step 2 - key contact information -->
    <div class="overflow-hidden">
      <div class="position-relative d-flex align-center py-3 px-5">
        <div :class="['form-icon step', step >= STEP.PERSON ? 'bg-orange' : 'bg-grey']">
          <ion-text>2</ion-text>
        </div>
        <ion-text
          class="ml-3"
          :class="step >= STEP.PERSON ? 'title-h2 fw-500' : ''"
          :color="step >= STEP.PERSON ? 'primary' : 'grey'"
        >
          {{ $t('key_contact.key_contact_information') }}
        </ion-text>
      </div>
      <transition-expand>
        <div v-if="step === STEP.PERSON" class="step-item">
          <KeyContactInformation
            @backStepOne="backStepOne"
            @handleDataKeyContactInformation="handleDataKeyContactInformation"
            @handleCheckDuplicatePerson="handleCheckDuplicatePerson"
            :keyContactInformation="keyContactInformation"
            :phoneProps="phone"
            :countryCodeProps="countryCode"
            :countryId="countryId"
            :isBranch="isBranch"
            :customerDetails="customerDetails"
            :sameAsParent_keyConTact="sameAsParent_keyConTact"
            :originCode="originCode"
          />
        </div>
      </transition-expand>
    </div>

    <!-- step 3 - Delivery Hours -->
    <div class="overflow-hidden">
      <div class="position-relative d-flex align-center py-3 px-5">
        <div :class="['form-icon step', step >= STEP.DELIVERY_HOURS ? 'bg-orange' : 'bg-grey']">
          <ion-text>3</ion-text>
        </div>
        <ion-text
          class="ml-3"
          :class="step >= STEP.DELIVERY_HOURS ? 'title-h2 fw-500' : ''"
          :color="step >= STEP.DELIVERY_HOURS ? 'primary' : 'grey'"
        >
          {{ $t('customerPage.delivery_hours') }}
        </ion-text>
      </div>
      <transition-expand>
        <div v-if="step === STEP.DELIVERY_HOURS" class="step-item">
          <hours-form
            :isEdit="false"
            :workingHours="[]"
            :workingHoursBranch="customerDetails"
            :defaultWorkingHours="groupsAndCountriesAndDays.days"
            :headCompanyHours="
              headId && headCompany && headCompany.working_hours ? headCompany.working_hours : null
            "
            :openTimeProps="openTime"
            :closeTimeProps="closeTime"
            :workingHoursProps="workingHourProps"
            :deliveryInstruction="deliveryInstruction"
            :isBranch="isBranch"
            :sameAsParent_delivery="sameAsParent_delivery"
            @handleDataDeliveryHours="handleDataDeliveryHours"
            @failed="openModalFailed"
            @skip="backStep"
          />
        </div>
      </transition-expand>
    </div>

    <!-- step 4 - Image -->
    <div class="overflow-hidden">
      <div class="position-relative d-flex align-center py-3 px-5">
        <div :class="['form-icon last-step', step >= STEP.IMAGES ? 'bg-orange' : 'bg-grey']">
          <ion-text>4</ion-text>
        </div>
        <ion-text
          class="ml-3"
          :class="step >= STEP.IMAGES ? 'title-h2 fw-500' : ''"
          :color="step >= STEP.IMAGES ? 'primary' : 'grey'"
        >
          {{ $t('customerPage.image_optional') }}
        </ion-text>
      </div>

      <transition-expand>
        <div v-if="step === STEP.IMAGES" class="step-item">
          <image-form
            @skip="backStep"
            @save-success="handleFinishForm"
            @save-image-step-form="handleDataSaveImage"
            :listAddImage="listAddImage"
            :listDeleteImage="listDeleteImage"
          />
        </div>
      </transition-expand>
    </div>

    <!-- modal info -->
    <ion-modal
      :is-open="isOpenModalInfoRef"
      css-class="modal-info custom-modal"
      @didDismiss="setOpenModalInfo(false)"
    >
      <modal-content-info :title="modalInfoTitle" :content="modalInfoContent" @close-modal="closeModalInfo">
      </modal-content-info>
    </ion-modal>

    <ion-modal :is-open="isOpenModalCuisine" backdropDismiss="false">
      <CuisinePreferences
        :customerId="customerId"
        :countryId="countryId"
        @later="handleChangePage"
        @apply="handleSelectionCuisine"
      />
    </ion-modal>

    <ion-modal :is-open="isOpenModalConfirmDuplicateCustomer" backdropDismiss="false">
      <ModalConfirmDuplicateCustomer
        @ok="createCustomer"
        @close-modal="setOpenModalConfirmDuplicateCustomer(false)"
      />
    </ion-modal>

    <!-- loading -->
    <ion-loading
      mode="ios"
      :is-open="isOpenLoadingref"
      @didDismiss="setOpenLoading(false)"
      cssClass="custom-loading"
      message=""
      spinner="crescent"
    >
    </ion-loading>
    <ion-loading
      mode="ios"
      :is-open="isOpenRefLoading"
      cssClass="my-custom-class"
      :message="$t('please_wait')"
      @didDismiss="setOpenLoadingDefault(false)"
    >
    </ion-loading>
  </div>
</template>

<script>
import { apolloClient } from '@/main';
import {
  addCustomerImage,
  getDefaultCreditTerm,
  hoursAdd,
  saleAddCustomer,
  saleGetCustomerDetail,
  updateCustomerImage
} from '@/modules/sale/services/graphql';
import { ACTIONS } from '@/modules/sale/store/customer/actions';
import BusinessInfomation from '@/modules/sale/views/customer/components/BusinessInfomation/index.vue';
import HoursForm from '@/modules/sale/views/customer/components/HoursForm.vue';
import ImageForm from '@/modules/sale/views/customer/components/ImageForm.vue';
import KeyContactInformation from '@/modules/sale/views/customer/components/KeyContactInformation/index.vue';
import ModalConfirmDuplicateCustomer from '@/modules/sale/views/customer/components/ModalConfirmDuplicateCustomer.vue';
import ModalContentInfo from '@/modules/sale/views/shared/ModalContentInfo.vue';
import TransitionExpand from '@/modules/sale/views/shared/TransitionExpand.vue';
import { errorAlert } from '@/utils/alert';
import { alertController } from '@ionic/vue';
import {
  checkmarkDoneOutline,
  createOutline,
  imageOutline,
  locationOutline,
  personOutline,
  timeOutline
} from 'ionicons/icons';
import { defineAsyncComponent, defineComponent, ref } from 'vue';
import { createNamespacedHelpers } from 'vuex';
import { STEP } from './constants';
const { mapActions, mapGetters } = createNamespacedHelpers('sale/customer');

export default defineComponent({
  name: 'AddCustomer',
  components: {
    BusinessInfomation,
    KeyContactInformation,
    HoursForm,
    ImageForm,
    TransitionExpand,
    ModalContentInfo,
    CuisinePreferences: defineAsyncComponent(() =>
      import('@/modules/shared/views/cuisine-preferences/index.vue')
    ),
    ModalConfirmDuplicateCustomer
  },
  data() {
    return {
      titleSuccess: this.$t('success'),
      titleFailed: this.$t('failed'),
      modalInfoTitle: '',
      modalInfoContent: '',
      contentSuccessPerson: this.$t('customerPage.person_information_saved'),
      contentSuccessAddress: this.$t('customerPage.address_saved'),
      contentSuccessDeliveryHours: this.$t('customerPage.delivery_hours_saved'),
      contentSuccessCompany: this.$t('customerPage.company_information_saved'),
      step: STEP.CUSTOMER,
      headId: Number(this.$route.query.headId),
      user: null,
      hasError: false,
      customerId: null,
      businessInformation: [],
      addressRefInput: '',
      sameAsParent_business: false,
      keyContactInformation: [],
      phone: '',
      countryCode: '',
      sameAsParent_keyConTact: false,
      deliveryHours: [],
      openTime: '',
      closeTime: '',
      workingHourProps: [],
      deliveryInstruction: '',
      isOpenRefLoading: false,
      sameAsParent_delivery: false,
      listAddImage: [],
      listDeleteImage: [],
      duplicatePostalCode: false,
      duplicatePerson: false,
      customerDetails: [],
      originCode: null,
      defaultCreditTerm: null
    };
  },
  setup() {
    // modal info
    const isOpenModalInfoRef = ref(false);
    const setOpenModalInfo = (state) => (isOpenModalInfoRef.value = state);

    // loading
    const isOpenLoadingref = ref(false);
    const setOpenLoading = (state) => (isOpenLoadingref.value = state);

    //modal cuisine
    const isOpenModalCuisine = ref(false);
    const setOpenModalCuisine = (state) => (isOpenModalCuisine.value = state);

    const isOpenModalConfirmDuplicateCustomer = ref(false);
    const setOpenModalConfirmDuplicateCustomer = (state) =>
      (isOpenModalConfirmDuplicateCustomer.value = state);

    return {
      // constant
      STEP,

      // icon
      createOutline,
      personOutline,
      locationOutline,
      timeOutline,
      imageOutline,
      checkmarkDoneOutline,

      // modal info
      isOpenModalInfoRef,
      setOpenModalInfo,
      // loading
      isOpenLoadingref,
      setOpenLoading,
      isOpenModalCuisine,
      setOpenModalCuisine,
      isOpenModalConfirmDuplicateCustomer,
      setOpenModalConfirmDuplicateCustomer,
      errorAlert
    };
  },
  inject: ['$storage'],
  async mounted() {
    this.user = await this.$storage.getUser();
    this.countryId = this.user.country.id;
    this.originCode = this.user.country.name;
    this[ACTIONS.GET_GROUPS_AND_COUNTRIES_AND_DAYS]();
    this.handleGetCustomerDetail();
    this.handleGetDefaultCreditTerm();
  },
  computed: {
    ...mapGetters(['groupsAndCountriesAndDays', 'status', 'error', 'list']),
    isBranch() {
      return this.$route.query.headId ? true : false;
    },
    isDuplicateCustomer() {
      return this.duplicatePostalCode && this.duplicatePerson && this.$route.query.headId === undefined;
    }
  },
  methods: {
    ...mapActions([
      ACTIONS.HOURS_ADD,
      ACTIONS.GET_GROUPS_AND_COUNTRIES_AND_DAYS,
      ACTIONS.GET_HEAD_COMPANY,
      ACTIONS.RESET_STATUS_ERROR
    ]),
    //get customer details when add branch use same as parent company
    async handleGetCustomerDetail() {
      try {
        if (this.isBranch) {
          const { data } = await apolloClient.query({
            query: saleGetCustomerDetail,
            variables: {
              id: this.headId
            }
          });
          this.customerDetails = data.saleGetCustomerDetail;
        }
      } catch (error) {
        this.errorAlert(error.message);
      }
    },
    async handleGetDefaultCreditTerm() {
      try {
        const { data } = await apolloClient.query({
          query: getDefaultCreditTerm,
          variables: {
            ...(this.isBranch && { mainCompayId: this.headId }),
            ...(!this.isBranch && { tenantId: this.user.tenant.id })
          }
        });
        this.defaultCreditTerm = data.getDefaultCreditTerm;
      } catch (error) {
        this.errorAlert(error.message);
      }
    },
    setOpenLoadingDefault(params) {
      this.isOpenRefLoading = params;
    },
    handleCheckDuplicatePostalCode(params) {
      this.duplicatePostalCode = params;
    },
    handleCheckDuplicatePerson(params) {
      this.duplicatePerson = params;
    },
    handleDataBusinessInformation(params, address, sameAsParent) {
      this.businessInformation = params;
      this.addressRefInput = address;
      this.sameAsParent_business = sameAsParent;
      this.nextStep();
    },
    handleDataKeyContactInformation(params, countryCode, phone, sameAsParent) {
      this.keyContactInformation = params;
      this.countryCode = countryCode;
      this.phone = phone;
      this.sameAsParent_keyConTact = sameAsParent;
      this.nextStep();
    },
    handleDataDeliveryHours(params, openTime, closeTime, workingHour, delivery_instruction, sameAsParent) {
      this.deliveryHours = params;
      this.openTime = openTime;
      this.closeTime = closeTime;
      this.workingHourProps = workingHour;
      this.deliveryInstruction = delivery_instruction;
      this.sameAsParent_delivery = sameAsParent;
      this.nextStep();
    },
    handleDataSaveImage(listAddImage, listDeleteImage) {
      this.listAddImage = listAddImage;
      this.listDeleteImage = listDeleteImage;
    },
    async handleAddCustomerIdListImage(params) {
      for (let i in this.listAddImage) {
        this.listAddImage[i].customer_id = params;
      }
    },
    async handleFinishForm() {
      if (this.isDuplicateCustomer) {
        this.setOpenModalConfirmDuplicateCustomer(true);
      } else {
        this.createCustomer();
      }
    },
    async createCustomer() {
      this.setOpenLoadingDefault(true);
      try {
        let params = {
          ...this.businessInformation,
          person: this.keyContactInformation,
          main_customer_id: Number(this.$route.query.headId),
          is_duplicate: this.isDuplicateCustomer
        };
        if (this.isBranch) {
          params.credit_term = this.customerDetails.credit_term;
        }
        const { data, errors } = await apolloClient.mutate({
          mutation: saleAddCustomer,
          variables: {
            item: params
          }
        });
        if (errors) {
          this.setOpenLoadingDefault(false);
          this.presentAlertError(errors[0].message);
          return;
        }
        this.customerId = data.saleAddCustomer.id;

        const dataDeliveryTime = this.deliveryHours.map((item) => ({
          ...item,
          customer_id: Number(data.saleAddCustomer.id)
        }));
        await apolloClient.mutate({
          mutation: hoursAdd,
          variables: {
            deliveryIntruction: this.deliveryInstruction,
            tenantId: this.customerDetails.tenant_id,
            payload: dataDeliveryTime
          }
        });
        if (this.listAddImage?.length !== 0) {
          await this.handleAddCustomerIdListImage(this.customerId);
          await apolloClient.mutate({
            mutation: addCustomerImage,
            variables: {
              payload: this.listAddImage
            }
          });
          await apolloClient.mutate({
            mutation: updateCustomerImage,
            variables: {
              id: this.listDeleteImage
            }
          });
        }
        this.setOpenLoadingDefault(false);
        this.setOpenModalCuisine(true);
      } catch (e) {
        this.setOpenLoadingDefault(false);
        this.presentAlertError(e);
      }
    },
    handleChangePage() {
      this.setOpenModalCuisine(false);
      if (this.$route.query.headId) {
        this.$router.push(`/sale/customers/${this.$route.query.headId}?tagged=true`);
      } else {
        this.$router.push(`/sale/customers/${this.customerId}?tagged=true`);
      }
    },
    handleSelectionCuisine() {
      this.setOpenModalCuisine(false);
      this.handleChangePage();
    },
    async handleSaveHoursCustomerDetails(payload, customerId) {
      payload = payload.map((item) => ({ ...item, customer_id: customerId }));
      const variables = {
        isDashboardPage: false,
        payload
      };
      await this.saveData(ACTIONS.HOURS_ADD, { variables, customerId }, this.contentSuccessDeliveryHours);
    },
    saveImage() {
      this.openModalInfo(this.contentSuccessCompany);
    },
    async saveData(action, params, successMessage) {
      this.setOpenLoading(true);
      await this[action](params);
      this.setOpenLoading(false);
      this.openModalInfo(successMessage);
    },
    backStep() {
      this.step = this.step - 1;
    },
    backStepOne(params, countryCode, phone, sameAsParent) {
      this.keyContactInformation = params;
      this.countryCode = countryCode;
      this.phone = phone;
      this.sameAsParent_keyConTact = sameAsParent;
      this.step = this.step - 1;
    },
    nextStep() {
      this.step = this.step + 1;
    },
    openModalInfo(messageSucces) {
      // show modal info
      if (this.status) {
        this.modalInfoTitle = this.titleSuccess;
        this.modalInfoContent = messageSucces;
      } else {
        this.modalInfoTitle = this.titleFailed;
        this.modalInfoContent = this.error.message;
        this.hasError = true;
        this[ACTIONS.RESET_STATUS_ERROR]();
      }
      this.setOpenModalInfo(true);
    },
    openModalFailed(errMsg) {
      this.modalInfoTitle = this.titleSuccess;
      this.modalInfoContent = errMsg;
      this.hasError = true;
      this.setOpenModalInfo(true);
    },

    closeModalInfo() {
      this.setOpenModalInfo(false);
      if (this.hasError) {
        this.hasError = false;
        return;
      }
      // last step
      if (this.step === this.STEP.IMAGES) {
        this.$router.push(`/sale/customers/${this.customerId}?tagged=true`);
      } else {
        setTimeout(() => this.nextStep(), 300);
      }
    },
    async presentAlertError(params) {
      const alert = await alertController.create({
        cssClass: 'my-custom-class',
        header: 'Error',
        message: params,
        buttons: [this.$t('OK')]
      });
      await alert.present();
    }
  }
});
</script>

<style src="./style.scss" lang="scss" scoped></style>
